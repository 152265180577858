import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Layout from '../components/layout';
import SEO from '../components/seo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => {
  return <Layout>
            <SEO title='Social' />
            {children}
        </Layout>;
};

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Social`}</h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/colinknebl"
        }}>{`Github`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.linkedin.com/in/colin-knebl-39480357/"
        }}>{`LinkedIn`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "mailto:colin.knebl@outlook.com"
        }}>{`Email`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.facebook.com/colin.knebl"
        }}>{`Facebook`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://twitter.com/ColinKnebl"
        }}>{`Twitter`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      